<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12" md="8">
        <v-card elevation="10" class="mx-auto" :loading="loading">
          <v-container>
            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
          </v-container>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <queue-detail
          :queueId="queueIdSelected"
          @queueUpdated="refreshFc"
          @queueDeleted="refreshFc"
        />
      </v-col>
    </v-row>
    <confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { calEvents, moveQueue } from "../api/queue";
import queueDetail from "./components/eventDetail";
export default {
  components: {
    FullCalendar,
    queueDetail,
    confirmDialog: () => import("@/components/confirmDialog")
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay"
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        showNonCurrentDates: false,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventChange: this.handleEventChange,
        events: this.getEvents
      },
      currentEvents: [],
      queueIdSelected: "",
      loading: false,
      fcApi: null
    };
  },
  watch: {},
  created() {},
  methods: {
    getEvents(info, success, fail) {
      this.loading = true;
      // console.log(info);
      calEvents(info.start.toISOString(), info.end.toISOString())
        .then(res => {
          success(res);
          // console.log(res.data);
        })
        .catch(err => fail(err))
        .finally(() => {
          this.loading = false;
        });
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(info) {
      // let title = prompt("Please enter a new title for your event");
      // let calendarApi = selectInfo.view.calendar;
      // calendarApi.unselect(); // clear date selection
      // if (title) {
      //   calendarApi.addEvent({
      //     id: "",
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   });
      // }
      let calApi = info.view.calendar;
      calApi.changeView("timeGridDay", info.startStr);
    },
    handleEventClick(info) {
      // console.log(info.event.id);
      this.fcApi = info.view.calendar;
      this.queueIdSelected = info.event.id;
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    async handleEventChange(info) {
      let event = info.event;

      let newDateStr = this.$moment(event.startStr).format("D-M-YYYY (HH:MM)");
      let dlg = await this.$refs.confirm.open(
        "ยืนยัน",
        `คุณต้องการเปลี่ยนคิว Q-${event.id} ไปวันที่ ${newDateStr} ?`,
        { label: "ส่ง SMS", show: true },
        {
          noconfirm: false
        }
      );
      console.log(dlg);
      if (dlg.agree) {
        // console.log(data);
        let data = {
          id: event.id,
          start: event.startStr,
          sendSms: dlg.checkbox
        };
        moveQueue(data.id, data).then(async res => {
          if (res.success) {
            // console.log(res);
            let smsStatus = res.smsStatus.message;
            await this.$refs.confirm.open(
              "ข้อมูลถูกบันทึก",
              `ทำการเปลี่ยนคิว Q-${event.id} ไปวันที่ ${newDateStr} เรียบร้อยแล้ว <br> <small>* ${smsStatus}</small>`,
              { show: false },
              {
                noconfirm: true
              }
            );
          }
        });
      }
    },
    refreshFc() {
      if (this.fcApi == null) return;

      this.fcApi.refetchEvents();
    }
  }
};
</script>

<style></style>
